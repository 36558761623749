import React, { useState, useEffect } from 'react'
// import useMousePosition from '../hooks/useMousePosition'

const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone')
dayjs.extend(utc)
dayjs.extend(timezone)

// console.log(dayjs().tz("America/New_York").unix())
// console.log(dayjs.unix(1626576911).tz("America/New_York").hour())

const lastClose = () => {
    let current = dayjs().tz("America/New_York")
    if (current.hour() > 16) {
        return (current.date(current.date() - 1))
    } else{
        return(current)
    }
}

const dateToX = () => {

}

/**
 * 
 * @param {Number} price Price for a given point in time
 * @param {Object} cgs Calculated Graph Settings
 * @param {Object} gs Graph Settings
 * @param {Number} graphHeight Height of graph (in pixels)
 */
const priceToY = (price, cgs, gs, graphHeight) => {
    const subFrom = (price - gs.min) * cgs.pixelsToDollar
    const percentSubFrom = graphHeight * gs.percentBelow / 100
    return (graphHeight - subFrom - percentSubFrom)
}

const xToSeconds = (x, daysHistorical, daysPredict, pixelsToDay) => {
    const totalDays = daysHistorical + daysPredict
    const historicalPixels = (daysHistorical / totalDays) * 1000
    const afterEven = x - historicalPixels
    return (((afterEven / pixelsToDay) * 24 * 3600) + lastClose().unix())
}

const yToPrice = (y, cgs, gs, graphHeight) => {

}



const GraphSVG = (props) => {
    const historicals = props.historicals
    const [lastPrice, setLastPrice] = useState(historicals.historicals[historicals.historicals.length - 1].close)
    const histPositive = useState(() => {
        if (lastPrice > historicals.historicals[0].close) {
            return true
        }
        return false
    })
    const graphSettings = props.graphSettings
    // {
    //     daysHistorical: 92,
    //     daysPredict: 200,
    //     percentAbove: 20,
    //     percentBelow: 20,
    //     max: 30,
    //     min: 10
    // }
    const [mousePosition, setMousePosition] = useState({x: -1, y: -1})
    const calculatedGraphSettings = props.calculatedGraphSettings
    // {
    //     "pixelsToDay": 3.4246575342465753,
    //     "pixelsToDollar": 30.931226666666664
    // }

    return (
        <svg version="1.1" baseProfile="full" 
                width="1000" height="300" 
                xlmns="http://www/w3/org/2000/svg"
                onMouseMove={(e) => setMousePosition({x: e.clientX - 10, y: e.clientY - 10})}
                style={{cursor: "crosshair"}}>
            {
                historicals.historicals.map((hist, idx) => {
                    if (idx === 0) {
                        return(<></>)
                    }
                    return (
                        <line x1={calculatedGraphSettings.pixelsToDay*(idx-1)} y1={priceToY(historicals.historicals[idx-1].close, calculatedGraphSettings, graphSettings, 300)} x2={calculatedGraphSettings.pixelsToDay*idx} y2={priceToY(hist.close, calculatedGraphSettings, graphSettings, 300)} style={
                            histPositive[0] ? {stroke: "rgb(0,200,0)", strokeWidth: 2} :
                        {stroke: "rgb(200, 0, 0)", strokeWidth: 2}} />
                    )
                })
            }
            {
                <line x1={calculatedGraphSettings.pixelsToDay * (historicals.historicals.length - 1)} y1={priceToY(lastPrice, calculatedGraphSettings, graphSettings, 300)} x2={mousePosition.x} y2={mousePosition.y} style={{stroke: "rgb(200,0,0)", strokeWidth: 2}} />
            }
            {
                <>
                <text x={mousePosition.x + 10} y={mousePosition.y - 3} fill="grey">To do (date)</text>
                <text x={mousePosition.x + 10} y={mousePosition.y + 18} fill="grey">To do (price)</text>
                </>
            }
        </svg>
    )
}

export default GraphSVG