import React, { useState, useEffect } from "react";
import GraphSVG from "./GraphSVG";

const styles = {
    GraphLight: {
        backgroundColor: '#ffffff',
        borderRadius: 4,
        width: 1000,
        height: 300,
        minHeight: 300,
        marginRight: 10,
        marginBottom: 10,
        border: '1px solid rgba(0, 0, 0, 0.15)',
        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)'
    }
}

const getMaxAndMin = (hists) => {
    let to_return = {
        max: 0,
        min: null
    }
    hists.historicals.map((hist) => {
        if (hist.close > to_return.max) {
            to_return.max = hist.close
        } else if (hist.close < to_return.min | !to_return.min) {
            to_return.min = hist.close
        }
    })
    return (to_return)
}

const Graph = (props) => {
    const [historicals, setHistoricals] = useState()
    const [graphSettings, setGraphSettings] = useState({
        daysHistorical: 92,
        daysPredict: 200,
        percentAbove: 20,
        percentBelow: 20,
        max: null,
        min: null
    })
    const [calculatedGraphSettings, setCalculatedGraphSettings] = useState({
        pixelsToDay: (1000 / 292),
        pixelsToDollar: null
    })
    const [stock, setStock] = useState("AAL")

    useEffect(() => {
        fetch('.netlify/functions/historicals', {
            method: 'POST',
            headers: {
                authorization: `Bearer $`,
                ticker: stock
            }
        })
        .then(res => res.json())
        .then((json) => {
            setHistoricals(json)
            const maxAndMin = getMaxAndMin(json)
            let newGraphSettings = graphSettings
            newGraphSettings.max = maxAndMin.max
            newGraphSettings.min = maxAndMin.min
            let newCalcGraphSettings = calculatedGraphSettings
            // newCalcGraphSettings.pixelsToDollar = (newGraphSettings.max * (1+(graphSettings.percentAbove / 100))) - (newGraphSettings.min * (1-(graphSettings.percentBelow / 100))) / 300
            const availablePixels = 300 - (graphSettings.percentAbove * 3) - (graphSettings.percentBelow * 3)
            newCalcGraphSettings.pixelsToDollar = availablePixels / (newGraphSettings.max - newGraphSettings.min)
            setGraphSettings(newGraphSettings)
            setCalculatedGraphSettings(newCalcGraphSettings)
        })
    }, [])

    return (
        <div style={styles.GraphLight} id="graphContainer">
            {
                historicals ? <GraphSVG historicals={historicals} graphSettings={graphSettings} calculatedGraphSettings={calculatedGraphSettings}/> : <p>Hi</p>
            }
        </div>
    )
}

export default Graph;