import * as React from "react";
import Graph from '../components/calculator/Graph'
import Chart from '../components/calculator/Chart'
import Chat from '../components/chat/Chat'
import style from '../styles/style.css'

const styles = {
  PageLight: {
    width: '100vw',
    height: '100vh',
    backgroundColor: '#ffffff',
    display: 'flex',
    justifyContent: 'left',
  },
  ContentLight: {
    backgroundColor: '#eeeeee',
    width: '1000px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '10px'
  },
  CalculatorChat: {
    height: '38%',
    marginBottom: '10px',
    display: 'flex',
    flexDirection: 'row'
  }
}

const IndexPage = () => {
    // {/* <Calculator ticker={'TSLA'} broker={['https://robinhood.com/stocks/', '']} dark={true}/> */}
  return (
    <div style={styles.PageLight}>
      <div style={styles.ContentLight}>
        <Graph/>
        <Chart/>
      </div>
    </div>
  )
}

export default IndexPage;